import { render, staticRenderFns } from "./Revistas.vue?vue&type=template&id=02e24c63&scoped=true&lang=pug&"
import script from "./Revistas.vue?vue&type=script&lang=js&"
export * from "./Revistas.vue?vue&type=script&lang=js&"
import style0 from "./Revistas.styl?vue&type=style&index=0&id=02e24c63&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02e24c63",
  null
  
)

export default component.exports